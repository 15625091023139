import React from 'react'
import * as classes from "../../sass/style.module.scss";
import cn from "classnames";

const AttentionBlock = ({ text, size = 'default' }) => {
    return (

        <div className={classes.attention}>
            <code className={classes.attentionBody}>
								<span
                                    dangerouslySetInnerHTML={{ __html: text }}
                                    className={cn(classes.attentionInfo, {
                                    [classes.attentionInfoBig]: size === 'big'
                                })
                                }/>

            </code>
        </div>
    )
}

export default AttentionBlock