import React from 'react'
import { L10nProvider } from '@logdoc/l10n';
import { InstallLogDoc } from '../install'

const In = () => {
    return (
        <L10nProvider>
            <InstallLogDoc/>
        </L10nProvider>
    )
}

export default In;
