import React from 'react'
import useLocale from '@logdoc/l10n';
import LayoutDefault from "../layouts";
import * as classes from '../sass/style.module.scss'
import DocInfoCode from "../components/SyntaxHighlighter";
import AttentionBlock  from "../components/Atention"
import { L10nProvider } from '@logdoc/l10n';

const In = () => {
    return (
        <L10nProvider>
            <InstallLogDoc />
        </L10nProvider>
    )
}

export const InstallLogDoc = () => {
    const t = useLocale()
    return (
        <LayoutDefault>
            <section className={classes.sec}>
                <div className={classes.secBreadcrumbs}>
                    <span>{t('install.title')}</span>
                    <span>/</span>
                    <span>{t('install.title.p1')}</span>
                </div>
                <h2 className={classes.secTitle}>
                    {t('install.title.p2')}
                </h2>
                <h5 className={classes.secSubtitleSecond}>
                    {t('install.title.p3')}
                </h5>
                <div className={classes.secBlock}>
                    <strong>{t('install.title.p4')}</strong>
                    <a name="requeries"/>
                    <div className={classes.secDescription}>
                        <ul>
                            <li>{t('install.db.p1')} <a href="https://www.postgresql.org/download/" target="_blank" style={{cursor: "pointer"}} className={classes.textOrange}>{t('install.db.p2')}</a>
                                <ul>
                                    <li>{t('install.db.p3')}</li>
                                    <li>{t('install.db.p4')}</li>
                                </ul>
                            </li>
                            <li>{t('install.db.p1')} <a href="https://clickhouse.com/docs/ru/getting-started/install"
                                        target="_blank" style={{cursor: "pointer"}}
                                        className={classes.textOrange}>ClickHouse</a>
                                <ul>
                                    <li>{t('install.db.p3')}</li>
                                    <li>{t('install.db.p5')}</li>
                                </ul>
                            </li>
                            <li><a href="https://jdk.java.net/"
                                   target="_blank" style={{cursor: "pointer"}}
                                   className={classes.textOrange}>JDK</a> {t('install.db.p6')}
                            </li>
                        </ul>
                    </div>
                    <br/>
                    <AttentionBlock text={t('install.db.p7')} />
                </div>
                <div className={classes.secBlock}>
                    <strong id="pg+click">
                        {t('install.db.p8')}
                    </strong>
                    <div className={classes.secDescription}>
                        <ul>
                            <li>{t('install.db.p9')}
                                <DocInfoCode text={`git clone https://github.com/LogDoc-org/LogDoc_Docker.git && cd LogDoc_Docker/`} language={`bash`}/>
                            </li>
                            <li>
                                {t('install.db.p10')} <DocInfoCode text={`docker-compose -f docker-compose-pg-click.yml up -d`} language={`bash`}/>
                            </li>
                            <li>
                                {t('install.db.p11')} <DocInfoCode text={`docker ps`} language={`bash`}/>
                                {t('install.db.p12')} <a href="http://127.0.0.1:9001/"
                                                       target="_blank">http://127.0.0.1:9001/</a><br/>
                                <em>{t('install.db.p13')} <span className={classes.textOrange}>admin</span> / <span
                                        className={classes.textOrange}>nimda</span></em>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={classes.secBlock}>
                    <strong>{t('install.db.p14')}</strong>
                    <div className={classes.secDescription}>
                        <ul>
                            <li>{t('install.db.p15')}
                                <DocInfoCode text={`curl https://logdoc.org/logdoc-community-latest.tgz | tar xz`} language={`bash`}/>
                            </li>
                            <li>
                                {t('install.db.p16')}
                                <em>./conf/application.conf</em> (<a
                                href="https://logdoc.org/config" target="_blank" style={{cursor: "pointer"}}>{t('install.db.p17')}</a>), {t('install.db.p18')}
                            </li>
                            <li>
                                {t('install.db.p19')}
                                <DocInfoCode text={`java -version`} language={`bash`}/>
                            </li>
                            <li>
                                {t('install.db.p20')}
                                <DocInfoCode text={`./bin/community`} language={`bash`}/>
                            </li>
                            <li>
                                {t('install.db.p21')} <a href="http://127.0.0.1:9000/"
                                                       target="_blank">http://127.0.0.1:9000/</a><br/>
                                <em>{t('install.db.p22')} <span className={classes.textOrange}>admin</span> / <span
                                        className={classes.textOrange}>nimda</span></em>
                            </li>
                        </ul>
                    </div>
                </div>
                <h5 className={classes.secSubtitleSecond}>{t('install.db.p23')}</h5>

                <div className={classes.secBlock}>
                    <strong>{t('install.db.p24')}</strong>

                    <div className={classes.secDescription}>
                        <iframe width="100%" height="315"
                                src="https://www.youtube.com/embed/YB073gA1KO8?si=uvsuQfHitun_QBbV"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                    </div>
                </div>
                <div className={classes.secBlock}>
                    <div className={classes.secDescription}>
                        <AttentionBlock text={`${t('install.db.p25')} <a href='https://docs.docker.com/network/drivers/host/'
                                    target='_blank'>${t('install.db.p26')}</a>`} />
                        <br/>
                        <br/>
                        <ul>
                            <li>{t('install.db.p27')}
                                <DocInfoCode text={`git clone https://github.com/LogDoc-org/LogDoc_Docker.git && cd LogDoc_Docker/`} language={`bash`}/>
                            </li>
                            <li>
                                {t('install.db.p28')} <DocInfoCode text={`docker-compose -f docker-compose.yml up -d`} language={`bash`}/>
                            </li>
                            <li>
                                {t('install.db.p29')} <a href="http://127.0.0.1:9001/"
                                                       target="_blank">http://127.0.0.1:9001/</a><br/>
                                <em>{t('install.db.p30')} <span className={classes.textOrange}>admin</span> / <span
                                        className={classes.textOrange}>nimda</span></em>
                            </li>
                        </ul>
                    </div>
                </div>
                <h5 className={classes.secSubtitleSecond}>
                    {t('install.db.p31')}
                </h5>
                <div className={classes.secBlock}>
                    <strong>{t('install.db.p32')}</strong>

                    <div className={classes.secDescription}>
                        <iframe width="100%" height="315"
                                src="https://www.youtube.com/embed/jcCJKZ4QECM?si=XnQDYTN9nGy2pfX6"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                    </div>
                </div>

                <div className={classes.secBlock}>
                    <div className={classes.secDescription}>
                        <AttentionBlock text={`${t('install.db.p33')} <a href='https://docs.docker.com/network/drivers/host/'
                                    target='_blank'>${t('install.db.p34')}</a>`} />
                        <br/>
                        <br/>
                    </div>
                    <strong>{t('install.db.p35')}</strong>
                    <AttentionBlock text={`${t('install.db.p36')} <a href="#requeries">${t('install.db.p37')}</a>`} />
                </div>
                <div className={classes.secBlock}>
                    <strong>{t('install.db.p38')}</strong>
                    <div className={classes.secDescription}>
                        <ul>
                            <li>{t('install.db.p39')}
                                <DocInfoCode text={`docker pull logdoc/community:latest`} language={`bash`}/>
                            </li>
                            <li>
                                {t('install.db.p40')}
                                <DocInfoCode text={`docker run -v ./data/logdoc:/logdoc/conf/keys -v ./logs/logdoc:/logdoc/logs -v ./configs/logdoc_application_config.conf:/logdoc/conf/application.conf -v ./configs/logdoc_logback_config.xml:/logdoc/conf/logback.xml  --network=host -e POSTGRES_HOST='localhost'  -e POSTGRES_PORT='15432'  -e POSTGRES_USER='logdoc' -e POSTGRES_PASSWORD='logdoc' -e POSTGRES_DB='logdoc'  -e CLICKHOUSE_HOST='localhost' -e CLICKHOUSE_HTTP_PORT='19944' -e CLICKHOUSE_USER='logdoc' -e CLICKHOUSE_PASSWORD='logdoc' -e CLICKHOUSE_DB='logdoc' -e LOGDOC_HOST_PORT='9001' --name=logdoc -d logdoc/community:latest
                                `} language={`bash`}/>
                            </li>
                            <li>
                                {t('install.db.p41')} <a href="http://127.0.0.1:9001/"
                                                       target="_blank">http://127.0.0.1:9001/</a><br/>
                                <em>{t('install.db.p42')} <span className={classes.textOrange}>admin</span> / <span
                                        className={classes.textOrange}>nimda</span></em>
                            </li>
                        </ul>
                    </div>
                </div>
                <h2 className={classes.secTitle}>
                    {t('install.db.p43')}
                </h2>
                <div className={classes.secBlock}>
                    <strong>{t('install.db.p44')}</strong>
                    <div className={classes.secDescription}>
                        <ul>
                            <li><a href="https://jdk.java.net/"
                                   target="_blank" style={{cursor: "pointer"}}
                                   className={classes.textOrange}>JDK</a> {t('install.db.p45')}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classes.secBlock}>
                    <strong>
                        {t('install.db.p46')}
                    </strong>
                    <div className={classes.secDescription}>
                        <ul>
                            <li>{t('install.db.p47')}
                                <DocInfoCode text={`curl https://logdoc.org/logdoc-portable-latest.tgz | tar xz`}
                                             language={`bash`}/>
                            </li>
                            <li>
                                {t('install.db.p48')}
                                <DocInfoCode text={`java -version`} language={`bash`}/>
                            </li>
                            <li>
                                {t('install.db.p49')}
                                <DocInfoCode text={`./bin/portable`} language={`bash`}/>
                            </li>
                            <li>
                                {t('install.db.p50')} <a href="http://127.0.0.1:9010/"
                                                       target="_blank">http://127.0.0.1:9010/</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </LayoutDefault>
    );
};

export default In;
